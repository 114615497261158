.main-container {
  --circle-height: 225px;
  --min-line-color: #dc5304;
  --max-line-color: #1186a6;

  @media (max-width: 768px) {
    --circle-height: 175px;
  }

  --circle-width: var(--circle-height);

  min-width: calc(var(--circle-width) * 1.5);
  min-height: calc(var(--circle-height) * 1.5);
  display: grid;
  place-items: center;

  .circle-container {
    width: var(--circle-width);
    height: var(--circle-height);
    background: #fff;
    border-radius: 50%;
    border-style: solid;
    border-width: thin;
    border-color: #bfbfbf;
    position: relative;
  }

  .lines {
    position: absolute;
    width: calc(var(--circle-height) / 2 * 1.4);
    height: calc(var(--circle-height) / 2);
    transform-origin: bottom left;
    left: 50%;
  }
  .zero-line {
    border-bottom: 1px dashed #b9babb;
    width: calc(var(--circle-height) / 2 * 1.8);
  }

  .line-min {
    /* border-bottom: 1px solid #dc5304; */
    border-bottom: 1px solid var(--min-line-color);
    width: calc(var(--circle-height) / 2 * 1.6);
  }

  .line-max {
    /* border-bottom: 1px solid #1287a8; */
    border-bottom: 1px solid var(--max-line-color);
    width: calc(var(--circle-height) / 2 * 1.4);

    /* .custom {
        position: relative;
        width: 100%;
        height: 100%;
    
        .inner {
          position: absolute;
          height: 25px;
          width: 25px;
          background-color: black;
          border-radius: 50%;
          display: inline-block;
          bottom: -12.5px;
          right: -25px;
        }
      } */
  }

  .label {
    position: absolute;
    right: 0%;
    bottom: 0%;
  }

  .label-max {
    color: var(--max-line-color);
  }

  .label-min {
    color: var(--min-line-color);
  }
}
